<template>
  <div id="app">
    <div class="content">
      <img class="logo" src="../assets/images/logo.png" />
      <div class="form">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "auth",
}
</script>

<style scoped lang="scss">
#app {
  align-items: center;
  justify-content: center;

  .content {
    padding-bottom: 100px;
  }

  .logo {
    width: 204px;
    height: 75px;
    margin: 18px 0;
  }

  .form {
    width: 486px;
    min-height: 385px;
    background: #fafafa;
    border-radius: 8px;
  }
}
</style>
